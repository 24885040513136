<template>
  <div class="help">
    <div class="help-center">
      <div class="help-header">
        <div class="header-text">
          <p></p>
          <br />
          <p class="fs48">帮助中心</p>
          <br />
          <p></p>
          <p></p>
          <p>Help center</p>
        </div>
      </div>
      <div class="help-text t1">
        <p>问题分类</p>
      </div>
      <div class="question-classify" >
        <a-row class="type-classify" type="flex">
          <a-col :span="6" v-for="(item,index) in lists" :key="index">
            <a class="it" :href="item.nextUrl" target="_blank">
              <div class="Img problem" :class="item.className" v-if="item.className!='account'">
                <!-- <img :src="item.imgUrl" alt="" /> -->
              </div>
              <a-icon type="team" :style="{fontSize:'60px',color:'#f04844',position: 'absolute',left:'20px'}" v-else />
              <div class="question-text">
                <p class="qusetion-title">{{ item.title }}</p>
                <p class="question-type">{{ item.question }}</p>
              </div>
            </a>
            
          </a-col>
        </a-row>
      </div>
      <!-- <div class="hot-issue">
        <div class="help-text t2">
          <p>热门问题</p>
        </div>
        <div class="type-hotitem">
          <HotItem v-for="item in hotItem" :key="item"></HotItem>
        </div>
      </div> -->
      <!-- 联系客服 -->
      <!-- <div class="relation-service">
        <div class="help-text t3">
          <p>联系客服</p>
        </div>
        <div class="service-box">
          <div class="service-left">
            <div class="Unnamed">
              <p></p>
            </div>
            <div class="left-text paddingleft top10">
              <p class="fs20">问题反馈</p>
              <p class="fs16">向我们反馈遇到的问题，为您解决遇到的问题</p>
              <p class="fs18 consult">
                <a>问题反馈</a>
              </p>
            </div>
          </div>
          <div class="service-right">
            <div class="Unnamed">
              <p></p>
            </div>
            <div class="right-text paddingleft">
              <p class="fs20">全国服务热线</p>
              <p ><a-space :size="10"><a-icon class="fs20" type="phone" /><span class="fs20red">0571-56612345</span></a-space></p>
              <p class="fs16"><a-space :size="10"><a-icon class="fs20" type="mail" /> kefu@asto-inc.com</a-space></p>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: ['imgUrl', 'title', 'question'],
  data() {
    return {
      lists: [
        {
          className: 'problem-info',
          title: '用户必看',
          question: '平台及流程介绍',
          nextUrl:'https://www.taxoss.zz91.cn/prod/helpcenter/用户必看.pdf'
        },
        {
          className: 'problem-register',
          title: '登录注册',
          question: '账号注册及登录',
          nextUrl:'https://www.taxoss.zz91.cn/prod/helpcenter/登录和注册用户.pdf'
        },
        {
          className: 'problem-merchandise',
          title: '商品管理',
          question: '发布商品及商品上下架流程',
          nextUrl:'https://www.taxoss.zz91.cn/prod/helpcenter/商品管理.pdf'
        },
        {
          className: 'problem-order',
          title: '订单采购',
          question: '采购商品及订单支付',
          nextUrl:'https://www.taxoss.zz91.cn/prod/helpcenter/订单采购.pdf'
        },
        {
          className: 'problem-seller',
          title: '卖家入驻',
          question: '卖家入驻细节介绍',
          nextUrl:'https://www.taxoss.zz91.cn/prod/helpcenter/卖家入驻.pdf'
        },
        {
          className: 'problem-buyer',
          title: '买家入驻',
          question: '买家入驻细节介绍',
          nextUrl:'https://www.taxoss.zz91.cn/prod/helpcenter/买家入驻.pdf'
        },
        {
          className: 'account',
          title: '账号管理',
          question: '登录的账号进行设置管理',
          nextUrl:'https://www.taxoss.zz91.cn/prod/helpcenter/账号管理.pdf'
        },
        {
          className: 'problem-pay',
          title: '支付操作',
          question: '订单支付及支付开通方法'
        },
        {
          className: 'problem-flow',
          title: '货流管理',
          question: '如何添加订单货流信息'
        },
        {
          className: 'problem-contract',
          title: '电子合同',
          question: '电子合同如何签署'
        },
        {
          className: 'problem-invoice',
          title: '税务发票',
          question: '税务登记及发票申请'
        },
        {
          className: 'problem-other',
          title: '其他问题',
          question: '其他常见问题'
        }
      ],
      hotItem: [1, 2, 3, 4]
    }
  }
}
</script>
<style lang="less" scoped>
.help {
  padding: 0 0 100px 0;
  .help-center {
    max-width: 1440px;
    min-width: 990px;
    @media screen and (max-width: 1440px){
        padding: 0 15px;
    }
    margin: 0 auto;
    .help-header {
      margin-top: 30px;
      width: 100%;
      height: 400px;
      display: flex;
      align-items: center;
      background-color: @primary;
      .header-text {
        width: 100%;
        height: 154px;
        line-height: 28px;
        text-align: center;
        color: #ffffff;
        font-size: 40px;
        font-weight: 400;
        font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑', sans-serif;
      }
    }
    .question-classify {
      width: 100%;
      .type-classify {
        /deep/ .ant-col {
          padding-right: 20px;
          &:nth-child(4n) {
            padding: 0;
          }
        }
        .it {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          height: 120px;
          background-color: #ffffff;
          box-sizing: border-box;
          position: relative;
          color: #333;
          &:hover{
            transform: scale(1.01,1.01);
            transition: transform 0.1s;
          }
          .Img {
            margin-left: 20px;
            position: absolute;
            overflow: hidden;
            top: 50%;
            transform: translate(0,-50%);
            color: #F04844;
            width: 60px;
            text-align: center;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .question-text {
            align-self: center;
            padding: 0 10px 0 100px;
            box-sizing: border-box;
          }
          .qusetion-title {
            font-size: 18px;
            padding: 0 0 10px 0;
          }
          .question-type {
            font-size: 14px;
            color: #999999;
          }
        }
      }
    }
    .hot-issue {
      .type-hotitem {
        display: flex;
        justify-content: space-between;
        width: 100%;;
        height: 258px;
        top: 1073px;
      }
    }
    .relation-service {
      .service-box {
        display: flex;
        top: 1102px;
        width: 100%;
        height: 249px;
        .service-left,
        .service-right {
          background-color: #fff;
          padding: 0 0 0 60px;
          display: flex;
          align-items: center;
          width: 720px;
          border: 1px solid rgba(237, 237, 237, 1);
          border-right: none;
        }
        .left-text,
        .right-text {
          p {
            padding: 10px 0;
          }
          .consult {
            cursor: pointer;
            margin-top: 20px;
            width: 200px;
            height: 45px;
            color: #ffffff;
            background-color: rgba(240, 72, 68, 1);
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            a {
              color: #ffffff;
            }
            &:hover {
              opacity: 0.8;
            }
          }
        }
        .Unnamed {
          margin-top: -40px;
          width: 80px;
          height: 80px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid rgba(237, 237, 237, 1);
          p {
            font-size: 36px;
          }
          &:hover {
            color: rgb(26, 188, 156);
          }
        }
        .fs20 {
          font-size: 20px;
          font-weight: 700;
        }
        .fs20red {
          font-size: 20px;
          font-weight: 400;
          color: #f04844;
        }
        .fs16 {
          font-size: 16px;
          font-weight: 400;
          color: #999999;
        }
        .paddingleft {
          padding: 0 0 0 30px;
        }
        .top10 {
          margin-top: 30px;
        }
        .fs18 {
          font-size: 18px;
          font-weight: 400;
        }
      }
    }
    .fs48 {
      font-size: 50px;
      font-weight: 700;
    }
    .help-text {
      padding: 40px 0 30px 0;
      width: 100%;
      height: 81px;
      font-family: '微软雅黑', sans-serif;
      font-size: 18px;
      text-align: left;
      font-weight: 400;
    }
  }
}
</style>